import Vue from 'vue'
import App from './App.vue'

import router from '@/router'
import store from './store/store'

// Auth via Amplify
import Auth from '@aws-amplify/auth'

Auth.configure({
  'aws_project_region': process.env.VUE_APP_AWS_REGION,
  'aws_cognito_region': process.env.VUE_APP_AWS_REGION,
  'aws_user_pools_id': process.env.VUE_APP_USER_POOL_ID,
  'aws_user_pools_web_client_id': process.env.VUE_APP_WEB_CLIENT_ID,
  'oauth': {
    'domain': process.env.VUE_APP_OAUTH_DOMAIN,
    'scope': [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    'redirectSignIn': process.env.VUE_APP_REDIRECT_SIGNIN,
    'redirectSignOut': process.env.VUE_APP_REDIRECT_SIGNOUT,
    'responseType': 'code'
  },
  'federationTarget': 'COGNITO_USER_POOLS',
  'Analytics': {
    'disabled': true
  }
})

// Vuetify
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
