import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@aws-amplify/auth'


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/layouts/Complex.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      // disclosure requests
      {
        path: '/disclosure',
        name: 'disclosure-list',
        component: () => import('@/views/disclosure/List.vue')
      },
      {
        path: '/disclosure/new',
        name: 'disclosure-new',
        component: () => import('@/views/disclosure/New.vue')
      },
      {
        path: '/disclosure/:id',
        name: 'disclosure-edit',
        component: () => import('@/views/disclosure/Edit.vue')
      },
    ]
  },
  {
    path: '',
    component: () => import('@/layouts/Simple.vue'),
    children: [
      {
        path: '/signin',
        name: 'signin',
        component: () => import('@/views/SignIn.vue')
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/NotFound.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next()
      })
      .catch(() => {
        next({
          path: '/signin'
        })
      })
  }
  next()
})


export default router
