import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// light backgounrd-color: f4f7f8
// dark backgounrd-color: 282d32

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#02a0d8',
        secondary: '#424242',
        accent: '#ff9800',
        error: '#e00000',
        info: '#006e94',
        success: '#00ce71',
        warning: '#ff6900'
      },
      dark: {
        // primary: '#ff6900',
        // secondary: '#424242',
        // accent: '#82b1ff',
        // error: '#ff5252',
        // info: '#2196f3',
        // success: '#4caf50',
        // warning: '#ffc107'
      }
    }
  }
}

export default new Vuetify(opts)
